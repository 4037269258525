<template>
  <div class="bg-white">
    <div class="flex items-center justify-between px-4 py-4 mt-2 bg-white">
      <TitlePlus
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.headline'
          )
        "
        :hide-plus="true"
      />

      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @cancel-date="onApplyFilterDateRange"
      />
    </div>

    <div
      class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
    >
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.totalPromotions'
          )
        "
        :value="getTotalPromos"
        variant="gray"
      />
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.tripTaken'
          )
        "
        :value="getTotalTripTaken"
        variant="gray"
      />
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.discounts'
          )
        "
        :value="getTotalDiscountAmount"
        variant="gray"
      />
    </div>

    <FSTable
      :fst-id="`riderPromotion`"
      :endpoint="`/dashboard/riders/${$route.params.id}/promotion-records/`"
      :headers="tableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <FSTableRowItem
            :text="item.promo_id"
            :truncate="-5"
            :to="{ name: 'ViewPromotion', params: { id: item.pass_id } }"
          />

          <FSTableRowItem :text="item.promo_title" />

          <FSTableRowItem :text="item.promo_category" />

          <FSTableRowItem :text="item.promo_code" />

          <FSTableRowItem :text="item.updated_at" date />

          <FSTableRowItem :text="item.expired_at" date />

          <FSTableRowItem :text="item.num_of_trips_availed" />

          <FSTableRowItem :text="item.num_of_trips_left" />

          <FSTableRowItem :text="`${symbol}${item.discount_amount}`" />

          <!-- <FSTableRowItem>
            <oto-eye-icon v-tooltip.bottom="'View & Download Invoice'" />
          </FSTableRowItem>

          <FSTableRowItem>
            <div
              class="refund-action"
              :class="item.is_refunded ? 'refunded' : 'refund'"
              @click="showRefundPopup(item)"
            >
              {{ item.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </FSTableRowItem> -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
// import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
// import RefundPopup from '@/views/transactions/RefundPopup.vue'
import SummaryCard from '@/components/cards/SummaryCard'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPromotion',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    SummaryCard,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total_promos: '0',
          trip_taken: '0',
          total_discounted: 0,
        },
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoId'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoTitle'
          ),
          width: '10%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoType'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.code'
          ),
          width: '10%',
          sort: 'code',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.appliedDate'
          ),
          width: '15%',
          sort: 'updated_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.expiredDate'
          ),
          width: '15%',
          sort: 'expired_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.tripAvailed'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.tripLeft'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.discountAmount'
          ),
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    getTotalPromos() {
      return `${this.indexMetaData?.summary?.total_promos}`
    },
    getTotalTripTaken() {
      return `${this.indexMetaData?.summary?.trip_taken}`
    },
    getTotalDiscountAmount() {
      return `${this.symbol} ${this.indexMetaData?.summary?.total_discounted}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>
