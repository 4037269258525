var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"flex items-center justify-between px-4 py-4 mt-2 bg-white"},[_c('TitlePlus',{attrs:{"title":_vm.$t(
          'components.riderDetailsManagement.diveIntoTab.promotions.headline'
        ),"hide-plus":true}}),_c('DateRangePicker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1),_c('div',{staticClass:"flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
          'components.riderDetailsManagement.diveIntoTab.promotions.summary.totalPromotions'
        ),"value":_vm.getTotalPromos,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
          'components.riderDetailsManagement.diveIntoTab.promotions.summary.tripTaken'
        ),"value":_vm.getTotalTripTaken,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
          'components.riderDetailsManagement.diveIntoTab.promotions.summary.discounts'
        ),"value":_vm.getTotalDiscountAmount,"variant":"gray"}})],1),_c('FSTable',{attrs:{"fst-id":"riderPromotion","endpoint":("/dashboard/riders/" + (_vm.$route.params.id) + "/promotion-records/"),"headers":_vm.tableHeaders},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.promo_id,"truncate":-5,"to":{ name: 'ViewPromotion', params: { id: item.pass_id } }}}),_c('FSTableRowItem',{attrs:{"text":item.promo_title}}),_c('FSTableRowItem',{attrs:{"text":item.promo_category}}),_c('FSTableRowItem',{attrs:{"text":item.promo_code}}),_c('FSTableRowItem',{attrs:{"text":item.updated_at,"date":""}}),_c('FSTableRowItem',{attrs:{"text":item.expired_at,"date":""}}),_c('FSTableRowItem',{attrs:{"text":item.num_of_trips_availed}}),_c('FSTableRowItem',{attrs:{"text":item.num_of_trips_left}}),_c('FSTableRowItem',{attrs:{"text":("" + _vm.symbol + (item.discount_amount))}})],1)})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }